<style lang="scss" scoped>
    .dashboard {
        width: 100%;
        // height: 100%;
        box-sizing: border-box;

        // display: flex;
        // flex-direction: column;
        .real-time-top {
            width: 100%;
            margin-bottom: 30px;
            border-radius: 10px;
            overflow: hidden;
            flex: none;

            .real-time-title {
                height: 70px;
                background-color: #f6f7fc;
                line-height: 70px;
                color: #474747;
                font-size: 18px;
                font-weight: 600;
                text-indent: 45px;
                position: relative;
            }

            .real-time-title::after {
                content: "";
                width: 12px;
                height: 12px;
                background-color: #feaf2f;
                border-radius: 50%;
                position: absolute;
                left: 18px;
                top: 30px;
            }

            .real-time-title::before {
                content: "";
                width: 12px;
                height: 12px;
                background-color: #432a8c;
                border-radius: 50%;
                position: absolute;
                left: 28px;
                top: 30px;
            }

            .real-time-content {
                background-color: #fff;
                padding: 1.77vw 8vw 2.14vw;
                box-sizing: border-box;
            }
        }

        .real-time-bottom {
            width: 100%;
            margin-bottom: 10px;
            border-radius: 10px;
            overflow: hidden;
            flex: 1;
            display: flex;
            flex-direction: column;

            .real-time-title {
                height: 70px;
                background-color: #f6f7fc;
                line-height: 70px;
                color: #474747;
                font-size: 18px;
                font-weight: 600;
                text-indent: 45px;
                position: relative;
                flex: none;
            }

            .real-time-title::after {
                content: "";
                width: 12px;
                height: 12px;
                background-color: #feaf2f;
                border-radius: 50%;
                position: absolute;
                left: 18px;
                top: 30px;
            }

            .real-time-title::before {
                content: "";
                width: 12px;
                height: 12px;
                background-color: #432a8c;
                border-radius: 50%;
                position: absolute;
                left: 28px;
                top: 30px;
            }

            .real-time-content {
                background-color: #fff;
                padding: 1.77vw 8vw 2.14vw;
                box-sizing: border-box;
            }
        }
    }

    .content-card {
        .box-card {
            width: 17.76vw;
            height: 10.99vw;
            border-radius: 10px;
            position: relative;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                box-shadow: 2px 5px 10px #d0d3dc;
                transform: translate(0px, -2px);
            }

            img {
                width: 8.9vw;
                height: 8.95vw;
                position: absolute;
                right: 0;
                top: 1vw;
                cursor: pointer;
            }
        }

        .box-card:nth-child(1) {
            background-image: radial-gradient(#f07f7c, #eb6866);
        }

        .box-card:nth-child(2) {
            background-image: radial-gradient(#f99b6d, #fa8749);
        }

        .box-card:nth-child(3) {
            background-image: radial-gradient(#73d18e, #48c771);
        }
    }

    .box-card-content {
        color: #fff;
        height: 11vw;

        .box-card-numbe {
            span {
                font-size: 14px;
            }
        }

        .box-card-title {
            font-size: 16px;
            margin-top: 32px;
        }
    }

    .board-content {
        padding: 1.25vw 1vw 0.99vw;
        box-sizing: border-box;
        background: #fff;
        height: 27vw;

        // flex: 1;
        .board-content-left {
            height: 100%;
            width: 100%;
        }
    }

    ::v-deep {
        .el-card__body {
            padding: 0 2.71vw;
            box-sizing: border-box;
            height: 100%;
        }

        .el-dialog .el-dialog__body {
            background: #E8EAF3;
            border-radius: 10px;
        }
    }

    .mapecharts-title {
        height: 70px;
        line-height: 100px;
        padding: 20px 0;
        margin: 0 40px;
        color: #353535;
        font-size: 16px;
        border-bottom: 1px solid #D7D5EB;
        display: flex;

        .mapecharts-tabs {
            margin: 0 20px;
            color: #353535;
            cursor: pointer;
            font-weight: 600;

        }

        .mapecharts-tabs.active {
            position: relative;
            color: #58419c;

        }

        .mapecharts-tabs.active::after {
            content: '';
            width: 90%;
            height: 6px;
            background-color: #58419c;
            border-radius: 2.5px;
            position: absolute;
            bottom: -23px;
            left: 50%;
            transform: translateX(-50%);
        }

    }

    // 分页
    .paging {
        margin-top: 50px;
        padding-bottom: 50px;
        text-align: right;
    }

    .mapecharts-table {
        padding: 20px 40px;
    }
</style>

<template>
    <section class="dashboard">
        <div class="real-time-top">
            <div class="real-time-title">实时数据</div>
            <div class="real-time-content content-card flex justify-between">
                <el-card class="box-card">
                    <img src="../../assets/images/settle-school.png" alt=""/>
                    <div class="box-card-content flex flex-column justify-center">
                        <div class="box-card-number">
                            <countTo :startVal="startVal" :endVal="home_data.schoolallNum" class="f36"
                                     :duration="3000"></countTo>
                            /
                            <countTo :startVal="startVal" :endVal="home_data.enterSchoolNum" :duration="3000"></countTo>
                            <span>个</span>
                        </div>
                        <p class="box-card-title">已入驻学校</p>
                    </div>
                </el-card>

                <el-card class="box-card">
                    <img src="../../assets/images/settle-teacher.png" alt=""/>
                    <div class="box-card-content flex flex-column justify-center">
                        <div class="box-card-number">
                            <countTo :startVal="startVal" :endVal="home_data.teacherNum" :duration="3000"></countTo>
                            <span>个</span>
                        </div>
                        <p class="box-card-title">已入驻教师</p>
                    </div>
                </el-card>
                <el-card class="box-card">
                    <img src="../../assets/images/settle-student.png" alt=""/>
                    <div class="box-card-content flex flex-column justify-center">
                        <div class="box-card-number">
                            <countTo :startVal="startVal" :endVal="home_data.studentNum" :duration="3000"></countTo>
                            <span>个</span>
                        </div>
                        <p class="box-card-title">已入驻学生</p>
                    </div>
                </el-card>
            </div>
        </div>

        <div class="real-time-bottom">
            <div class="real-time-title">数据面板</div>
            <div class="board-content flex justify-between">
                <div ref="chartSettled" class="board-content-left flex1">
                    <!-- 返回中国地图 -->
                    <div ref="china_map" style="height: 100%; width: 100%"></div>
                </div>

                <div class="board-content-right flex1">
                    <div ref="shool_data" style="height: 100%; width: 100%"></div>
                </div>
            </div>
        </div>

        <!-- 地图学校分布情况 -->
        <el-dialog :show-close="true" :visible.sync="mapechartsVisible" class="mapecharts" width="1200px">
            <ul class="mapecharts-title">
                <li class="mapecharts-tabs" :class="shoolcut == 1 ? 'active' : ''" @click="shoolcut = 1">学校</li>
                <!-- <li class="mapecharts-tabs" :class="shoolcut == 2 ? 'active' : ''" @click="shoolcut = 2">教育局</li> -->
            </ul>

            <div class="mapecharts-table">


                <div class="table_center">
                    <el-table :data="tableData" style="width: 100%" :header-cell-style="{
            background: '#E8EAF3',
            color: '#1a1a1b',
            'text-align': 'center',
            fontWeight: 500,
          }">

                        <el-table-column prop="scsch_id" label="ID"></el-table-column>
                        <el-table-column prop="scsch_name" label="学校名称" min-width="140"></el-table-column>
                        <el-table-column prop="scsch_province_city_area" label="省/市/区"
                                         min-width="140"></el-table-column>
                        <!-- <el-table-column prop="market" label="市"> </el-table-column> -->
                        <el-table-column prop="type" label="学校类型">
                            <template slot-scope="scope">
                                <p>{{ scope.row.scsch_type == 10 ? "小学" : scope.row.scsch_type == 20 ? "初中" :
                                    scope.row.scsch_type == 30 ?
                                    "高中" : scope.row.scsch_type == 40 ? "职高" : '未知' }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="scuse_name" label="学校管理员"></el-table-column>
                        <!-- <el-table-column prop="create_time" label="添加时间"> </el-table-column> -->
                        <el-table-column label="状态" width="100">
                            <template slot-scope="scope">
                <span :class="{ normal: scope.row.scsch_status == 10, unusual: scope.row.scsch_status == 20 }">{{
                  scope.row.scsch_status == 10 ? "启用" : "禁用" }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>


                <div class="paging">
                    <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
                                   :page-size="this.searchform.pagesize" next-text="下一页" layout=" pager, next, jumper"
                                   :total="total">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>


    </section>
</template>

<script>
    import {schoolList} from "@api/school";
    import countTo from "vue-count-to";
    import "echarts/map/js/china.js"; // 核心文件
    import {loopgraph} from "@/utils/allecharts"
    import {homeTotal, getAreaData} from "@api/user";
    import axios from "axios";

    export default {
        components: {},
        data() {
            return {
                amount: 120,
                startVal: 0,
                mapechartsVisible: false,
                options: null, //echarts 存数据


                currentPage: 1,
                total: 0,
                searchform: {
                    pageindex: 1,
                    pagesize: 5,
                },
                home_data: {},//首页数据

                // 学校.教育局数据切换

                shoolcut: 1,
                // 表格数据
                tableData: [],

            };
        },
        components: {countTo},
        watch: {},

        computed: {},
        created() {
            this.init();
        },

        methods: {
            /**初始化 */
            async init() {
                const res = await homeTotal();

                if (res.status == 200) {
                    this.home_data = res.data;

                    this.drawshoolChart();
                    this.chinaMaprsult();
                }

                this.searchform.scsch_is_examine = 10;
                const ress = await schoolList(this.searchform);
                if (ress.status == 200) {
                    this.tableData = ress.data.data;
                    this.total = ress.data.allcount;
                }
            },
            // 渲染地图
            async chinaMaprsult() {
                let myChart = this.$echarts.init(this.$refs.china_map);
                let areaData = this.home_data.area;

                if (areaData == null) {
                    return;
                }
                //判断文件是否存在
                getAreaData({'area': areaData.ddi_addcode + ".json"}).then((res) => {

                    if (res.status == 200) {
                        let area = JSON.parse(res.data.area)
                        this.$echarts.registerMap(areaData.ddi_name, area, {});

                        let ddi_name = areaData.ddi_name;
                        let enterSchoolNum = this.home_data.enterSchoolNum;
                        let option = {
                            tooltip: {
                                trigger: "item",
                                formatter: function (a, b) {

                                    return (
                                        `入驻学校: ${a['data'].school}`
                                    )
                                }
                            },
                            series: [
                                {
                                    type: "map",
                                    mapType: area.features[0].properties.name,
                                    label: {
                                        show: true,
                                        color: "#333",
                                    },
                                    data: [{name: area.features[0].properties.name, school: enterSchoolNum}],
                                    itemStyle: {
                                        normal: {
                                            areaColor: "#199ED8",
                                            borderColor: "#199ED8",
                                        },
                                        emphasis: {
                                            areaColor: "#66FFFF",
                                        },
                                    },
                                },
                            ],
                        };
                        myChart.setOption(option);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
                // 点击地图
                let this_ = this;
                myChart.on('click', function (params) {

                    this_.mapechartsVisible = true;
                })

            },

            // 判断本地是否有该文件
            showjson(url) {
                // 判断结果

//       result = new Promise((resolve, reject) => {
//         result ="https://yucaidi.oss-cn-chengdu.aliyuncs.com/static/default/json/110101.json";
//         const xhr = new XMLHttpRequest();
//         xhr.open('HEAD', url);
//         xhr.onreadystatechange = function () {
//           if (xhr.readyState === 4) {
//             if (xhr.status === 200) {
//               result = url; // 文件存在
//             } 

//           }
//         };
//         xhr.send();
//       });
// console.log(result);

                // 返回结果
                result = url;
                return result
            },

            // 学校分布echarts
            drawshoolChart() {
                // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
                // let myChart = this.$echarts.init(this.$refs.shool_data);


                // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
                let myChart = this.$echarts.init(this.$refs.shool_data);
                let schStatistic = this.home_data.schStatistic;
                let data = [
                    {value: schStatistic.primarySchool, name: "小学"},
                    {value: schStatistic.elementarySchool, name: "初中"},
                    {value: schStatistic.highSchool, name: "高中"},
                    {value: schStatistic.vocationalSchool, name: "职高"},
                ];
                let option = loopgraph(data);
                myChart.setOption(option);

            },

            // 修改当前
            handleCurrentChange() {
                this.searchform.pageindex = this.currentPage;
                this.init()
            },


        },

    };
</script>
